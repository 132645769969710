<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/Travis_1.jpg">
    <div class='text1'>
      “Business cards are the backbone of America”
    </div>
    <div class='text2'>
      - Travis J. Kazar
    </div>
  </div>
</template>
<style scoped>
.text1{
  font-size: 32px;
}
</style>
<script>
// @ is an alias to /src

export default {
  name: 'Home',
  mounted(){
    var audio = new Audio(require('../assets/Graph.mp3'))
    audio.play();
  }
}
</script>
